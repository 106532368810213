import { buildings } from './buildings';
import { average, scaleValue } from '../util';

let DEBUG_MODE;
const BUILDING_EXPANSION = 1.2;
// const DISTANCE_THRESHOLD = 0.0034;
const DISTANCE_THRESHOLD = 0.0025;
// 東通りから都庁第1で0.0034

const player = { coord: [35.69114932031703, 139.69498750466585], direction: 0 }; // 西新宿東通り
// const player = { coord: [35.70682789078975, 139.69105502147454], direction: 0 }; // 自宅
const beam = [[0, 0], [0, 0]];

// https://qiita.com/zu_rin/items/e04fdec4e3dec6072104
const intersect = (a, b, c, d) => {
  let s, t;
  s = (a[0] - b[0]) * (c[1] - a[1]) - (a[1] - b[1]) * (c[0] - a[0]);
  t = (a[0] - b[0]) * (d[1] - a[1]) - (a[1] - b[1]) * (d[0] - a[0]);
  if (s * t > 0) {
    return false;
  }
  s = (c[0] - d[0]) * (a[1] - c[1]) - (c[1] - d[1]) * (a[0] - c[0]);
  t = (c[0] - d[0]) * (b[1] - c[1]) - (c[1] - d[1]) * (b[0] - c[0]);
  if (s * t > 0) {
    return false;
  }
  return true;
};

const bounds = {};
let currentHit = null;
let outOfArea = false;

const setViewSize = viewSize => {
  bounds.view = {
    range: {
      x: { min: 0, max: viewSize[0] },
      y: { min: 0, max: viewSize[1] }
    },
    aspect: viewSize[0] / viewSize[1]
  };

  const { map, view } = bounds;

  bounds.map.mapping = {
    x: { min: map.range.x.min, max: map.range.x.max },
    y: { min: map.range.y.min, max: map.range.y.max }
  };

  if (map.aspect > view.aspect) {
    const scale =
      (view.range.x.max - view.range.x.min) /
        (map.range.x.max - map.range.x.min);
    const centerY = (view.range.y.min + view.range.y.max) / 2;
    const halfY = (map.range.y.max - map.range.y.min) * scale / 2;
    bounds.view.mapping = {
      x: { min: view.range.x.min, max: view.range.x.max },
      y: { min: centerY - halfY, max: centerY + halfY }
    };
  } else {
    const scale =
      (view.range.y.max - view.range.y.min) /
        (map.range.y.max - map.range.y.min);
    const centerX = (view.range.x.min + view.range.x.max) / 2;
    const halfX = (map.range.x.max - map.range.x.min) * scale / 2;
    bounds.view.mapping = {
      x: { min: centerX - halfX, max: centerX + halfX },
      y: { min: view.range.y.min, max: view.range.y.max }
    };
  }
  console.log(bounds);

};

const mapToViewCoord = coordOnMap => [
  scaleValue(
    coordOnMap[0],
    bounds.map.mapping.x.min,
    bounds.map.mapping.x.max,
    bounds.view.mapping.x.min,
    bounds.view.mapping.x.max
  ),
  scaleValue(
    coordOnMap[1],
    bounds.map.mapping.y.min,
    bounds.map.mapping.y.max,
    bounds.view.mapping.y.min,
    bounds.view.mapping.y.max
  )    
];

const viewToMapCoord = coordOnView => [
  scaleValue(
    coordOnView[0],
    bounds.view.mapping.x.min,
    bounds.view.mapping.x.max,
    bounds.map.mapping.x.min,
    bounds.map.mapping.x.max
  ),
  scaleValue(
    coordOnView[1],
    bounds.view.mapping.y.min,
    bounds.view.mapping.y.max,
    bounds.map.mapping.y.min,
    bounds.map.mapping.y.max
  )
];

{
  for (const building of buildings) {
    building.center = average(building.coords);
    for (const coord of building.coords) {
      coord[0] = (coord[0] - building.center[0]) * BUILDING_EXPANSION + building.center[0];
      coord[1] = (coord[1] - building.center[1]) * BUILDING_EXPANSION + building.center[1];
    }
  }


  const allCoords = buildings.map(({ coords }) => coords).flat();
  const range = {
    x: {
      min: Math.min(...allCoords.map(([x, y]) => x)),
      max: Math.max(...allCoords.map(([x, y]) => x))
    },
    y: {
      min: Math.min(...allCoords.map(([x, y]) => y)),
      max: Math.max(...allCoords.map(([x, y]) => y))
    }
  };
  const edgeLength = [
    range.x.max - range.x.min,
    range.y.max - range.y.min,
  ];
  const aspect = edgeLength[0] / edgeLength[1];
  bounds.map = { range, aspect };
  console.log(buildings)
}

const getViewCoords = target => {
  // console.log(player.coord, beam[0], beam[1]);
  return {
    buildings: () => buildings.map(
      ({ coords }) => coords.map(coord => mapToViewCoord(coord))
    ),
    player: () => mapToViewCoord(player.coord),
    beam: () => [
      mapToViewCoord(beam[0]),
      mapToViewCoord(beam[1])
    ]
  }[target]();
};

const detectBuilding = () => {

  if (!tiltValidForDetection) {
    currentHit = null;
    return;
  }
  
  // const t = performance.now();
  beam[0] = player.coord;
  beam[1] = [
    player.coord[0] + Math.cos(player.direction - Math.PI * 0.5) * DISTANCE_THRESHOLD,
    player.coord[1] + -Math.sin(player.direction - Math.PI * 0.5) * DISTANCE_THRESHOLD
  ];
  
  for (const building of buildings) {
    building.distance =
      Math.hypot(
        building.center[0] - player.coord[0],
        building.center[1] - player.coord[1]
      );
  }
  const _buildings = buildings
    .filter(({ distance }) => distance < DISTANCE_THRESHOLD)
    .sort((a, b) => a.distance - b.distance);
  outOfArea = _buildings.length <= 0;
  
  let hit;
  for (const building of _buildings) {
    for (let i = 0; i < building.coords.length - 1; i += 1) {
      if (intersect(
        beam[0],
        beam[1],
        building.coords[i],
        building.coords[i + 1]
      )) {
        hit = building;
        break;
      }
    }
    if (hit) break;
  }
  currentHit = hit || null;
  // if (hit) {
  //   // console.log(hit.name);
  //   currentHit = hit;
  // }
};

let lastPlayerDirection;
let tiltValidForDetection;
const setPlayerDirection = (rad, tilt) => {
  if (tilt) {
    tiltValidForDetection = tilt > 30;
  }
  if (rad !== lastPlayerDirection) {
    player.direction = rad;
    detectBuilding();
    lastPlayerDirection = player.direction;
  }
}

let lastPlayerCoord = [];
const setPlayerCoord = coord => {
  if (
    coord[0] !== lastPlayerCoord[0] &&
    coord[1] !== lastPlayerCoord[1]
  ) {
    player.coord = coord;
    detectBuilding();
    lastPlayerCoord = player.coord;
  }
}

export default {
  buildings,
  setViewSize,
  mapToViewCoord,
  viewToMapCoord,
  getViewCoords,
  setDebugMode(status) {
    DEBUG_MODE = status;
  },
  setPlayerDirection,
  setPlayerCoord,
  get currentHit() {
    return currentHit;
  },
  get outOfArea() {
    return outOfArea;
  }
};