// TODO: コンプ入れる？

import { radToDeg, degToRad, scaleValue, osName, loadAudioBuffer, MovingAverage } from '../util';
import LoopTracker from './loop-tracker';

const masterInfo = require('./master.json');
import soundList from './sound-list.js';
console.log(soundList);

import { unmute } from './unmute';

let audioContext;
let audioStarted;
let masterNode, analyzerNode;
let frequencyData;
let fileList;
let tracker;
let lastHit;
// let hitSoundBuffer;
// let hitGain;

const PI2 = Math.PI * 2;

class Panner {
  static updateListeners = [];
  static {
    window.setInterval(() => {
      for (const listener of Panner.updateListeners) {
        listener.onUpdate();
      }
    }, 100);
  }
  constructor(audioContext) {
    let pannerNode;
    const pan = 0;
    if (audioContext.createStereoPanner) {
      this.pannerType = 'stereo';
      pannerNode = audioContext.createStereoPanner();
      pannerNode.pan.value = pan;
    } else {
      this.pannerType = '3d';
      pannerNode = audioContext.createPanner();
      pannerNode.panningModel = 'equalpower';
      pannerNode.setPosition(pan, 0, 1 - Math.abs(pan));
    }
    this.pannerNode = pannerNode;

    this.gainNode = audioContext.createGain();
    this.pannerNode.connect(this.gainNode);

    this.gainValue = new MovingAverage(5);
    this.amplitude = 0;

    Panner.updateListeners.push(this);
  }
  onUpdate() {
    const { currentTime } = this.pannerNode.context;
    this.gainNode.gain.linearRampToValueAtTime(
      this.gainValue.push(this.amplitude),
      currentTime + 0.1
    );
  }
  setPan(angle, _amplitude) {
    const { pannerNode } = this;
    const { currentTime } = pannerNode.context;
    const t = currentTime + 0.1;
    // pan = scaleValue(pan, -1, 1, -0.8, 0.8);

    const pan = -Math.sin(angle);
    // const angleAbs = Math.min(
    //   Math.abs(angle),
    //   Math.abs(PI2 - angle)
    // );
    // const MIN_AMPLITUDE = 0.3;
    // const amplitude = Math.max(
    //   scaleValue(angleAbs, 0, degToRad(90), 1.0, MIN_AMPLITUDE),
    //   MIN_AMPLITUDE
    // );

    switch (this.pannerType) {
      case 'stereo':
        pannerNode.pan.linearRampToValueAtTime(pan, t);
        break;
      case '3d':
        pannerNode.setPosition(pan, 0, 1 - Math.abs(pan));
        break;
      default:
        break;
    }
    // console.log('amplitude', amplitude, angleAbs);

    this.amplitude = _amplitude;
  }
  get inputNode() {
    return this.pannerNode;
  }
  get outputNode() {
    return this.gainNode;
  }
}


const dbtoa = db => Math.pow(0.1, db / -20);
const atodb = amp => Math.log(amp) / Math.log(0.1) * -20;
const rampByDb = (gainNode, targetValue, startTime, duration) => {
  const { gain } = gainNode;
  const { currentTime } = gainNode.context;
  gain.cancelScheduledValues(currentTime);
  const currentValue = gain.value;
  const currentDb = Math.max(atodb(currentValue), -60);
  const targetDb = Math.max(atodb(targetValue), -60);
  const curve = new Float32Array(11);
  for (let i = 0; i < 10; i += 1) {
    const db = scaleValue(i, 0, 9, currentDb, targetDb);
    curve[i] = dbtoa(db);
  }
  curve[10] = Math.max(targetValue, Number.EPSILON);
  gain.setValueCurveAtTime(curve, startTime, duration);
};

const getDistance = (latlon1, latlon2) => {
  const {sin, cos, atan2, sqrt} = Math;
  const [lat1, lon1] = latlon1;
  const [lat2, lon2] = latlon2;
  const R = 6371; // Radius of the earth in km
  const dLat = degToRad(lat2 - lat1);
  const dLon = degToRad(lon2 - lon1); 
  const a = 
    sin(dLat / 2) * sin(dLat / 2) +
    cos(degToRad(lat1)) * cos(degToRad(lat2)) * 
    sin(dLon / 2) * sin(dLon / 2)
    ; 
  const c = 2 * atan2(sqrt(a), sqrt(1 - a)); 
  const d = R * c; // Distance in km
  return d * 1000;
};

const getAngle = (coordsA, coordsB, direction) => {
  const directionToPoint = Math.atan2(
    coordsA[0] - coordsB[0],
    coordsA[1] - coordsB[1]
  );
  return (directionToPoint - direction + PI2) % PI2;
};

class PointSounder {
  constructor(info) {
    this.info = info;
    console.log(info);
    this.active = false;
    this.amplitude = 0;

    this.gainNode = audioContext.createGain();
    this.gainNode.gain.setValueAtTime(Number.EPSILON, audioContext.currentTime);
    this.gainNode.connect(masterNode);

    this.pannerNode = new Panner(audioContext);
    this.pannerNode.outputNode.connect(this.gainNode);

    this.track = tracker.createTrack(
      `chunks/${this.info.file}`,
      fileList[this.info.file].count,
      this.info.loopBeats,
      this.pannerNode.inputNode
    );
  }
  async updateUserCoord(userCoords) {

    if (!tracker.started) {
      return;
    }

    const {
      coords, distanceDecay, distanceLimit, amplitudeCurve, volume
    } = this.info;

    const distance = getDistance(userCoords, coords);
    
    this.amplitude = Math.pow(
      Math.max(scaleValue(distance, 0, distanceDecay, 1, 0), 0),
      Math.pow(2, amplitudeCurve)
    ) * volume;

    const AMPLITUDE_THRESHOLD = 0.001;
    if (distance > distanceLimit) {
      this.amplitude = 0;
    }
    this.active = this.amplitude > AMPLITUDE_THRESHOLD;
    
    rampByDb(
      this.gainNode, 
      this.amplitude,
      audioContext.currentTime,
      1.0
    );

    // if (this.active && !this.buffer) {
    //   this.buffer = await loadAudioBuffer(file);
    // }

    this.track.setActive(this.active);

    this.userCoords = userCoords;

    // console.log(
    //   this.info.label,
    //   this.active,
    //   distance,
    //   this.amplitude
    // );
    
  }
  async updateUserDirection (userDirection, amplitude) {

    if (!tracker.started || !this.userCoords) {
      return;
    }

    const {
      coords
    } = this.info;

    const angle = getAngle(coords, this.userCoords, userDirection);
    this.pannerNode.setPan(angle, amplitude);

    // console.log(
    //   this.info.label,
    //   radToDeg(angle),
    // );

  }
  getAmplitude() {
    return (
      (fileList[this.info.file].rms?.[this.track.getCurrentFrame()]) || 0
    ) * this.amplitude;
  }

}

const Sounders = {
  point: PointSounder
};

const startAudio = async () => {

  if (audioStarted) {
    return;
  }
  audioStarted = true;

  audioContext = new (window.AudioContext || window.webkitAudioContext)();
  unmute(audioContext, true);

  masterNode = audioContext.createGain();
  masterNode.gain.value = 1.0;
  masterNode.connect(audioContext.destination);

  analyzerNode = audioContext.createAnalyser();
  analyzerNode.fftSize = 1024;
  const bufferLength = analyzerNode.frequencyBinCount;
  frequencyData = new Uint8Array(bufferLength);
  masterNode.connect(analyzerNode);
  
  { // 開始サウンドを再生　iOSでオーディオ有効化するため　ユーザトリガーによる再生処理
    const source = audioContext.createBufferSource();
    source.connect(masterNode);
    source.buffer = await loadAudioBuffer(audioContext, `chunks/start/chunk001.${osName === 'ios' ? 'caf' : 'opus'}`);
    source.start();
  }

  // hitSoundBuffer = await loadAudioBuffer(audioContext, `chunks/SFX--Focus_SFX_01/chunk001.${osName === 'ios' ? 'caf' : 'opus'}`);
  // hitGain = audioContext.createGain();
  // hitGain.gain.value = 0.2;
  // hitGain.connect(audioContext.destination);

  fileList = await window.fetch('chunks/list.json').then(res => res.json());

  tracker = new LoopTracker(audioContext, masterInfo.clock.bpm);

  for (const sound of soundList) {
    if (!sound.enabled) {
      continue;
    }
    sound.player = new (Sounders[sound.type])(sound);
  }
    
  tracker.start();
};

const updateUserCoord = coords => {
  for (const { player } of soundList) {
    player?.updateUserCoord(coords);
  }
};

const updateUserDirection = (direction, currentHit) => {
  // console.log(currentHit);
  for (const { player } of soundList) {
    if (player) {
      let amplitude = 0.6;
      if (currentHit) {
        if (player.info.id === currentHit.id) {
          
          amplitude = 1.0;
        } else {
          amplitude = 0.1;
        }
      }
      player.updateUserDirection(direction, amplitude);
    }
  }

  // if (hitGain && !!currentHit && (currentHit !== lastHit)) {
  //   const source = audioContext.createBufferSource();
  //   source.connect(hitGain);
  //   source.buffer = hitSoundBuffer;
  //   source.start();
  //   source.addEventListener('ended', () => {
  //     console.log('sfx ended.');
  //     source.buffer = null;
  //     source.disconnect();
  //   });
  // }
  lastHit = currentHit;
};

const getAmplitudes = () => {
  const list = {};
  for (const { player } of soundList) {
    if (player) {
      list[player.info.id] = player.getAmplitude();
    }
  }
  return list;
};

const getSpectrum = () => {
  analyzerNode?.getByteFrequencyData(frequencyData)
  return frequencyData;
};

// #####################################
if (typeof window.SB === 'undefined') {
  window.SB = {};
}

const SBAudio = {
  startAudio,
  updateUserCoord,
  updateUserDirection,
  getAmplitudes,
  getSpectrum
};

export default SBAudio;
window.SB.Audio = SBAudio;
