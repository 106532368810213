import { average, getURLParam } from "../util";

const place = getURLParam('debug_place') || 'shinjuku';

console.log('place', place);

const _buildings = {};

_buildings.shinjuku = [
  {
    id: 'stec-bldg',
    displayName: {
      ja: 'エステック情報ビル',
      en: 'S-tec Information Building'
    },
    coords: [
      [35.69046531760668, 139.69565136792602],
      [35.69048525525748, 139.69612067363138],
      [35.690182671550225, 139.69615244201762],
      [35.69015804259328, 139.69567591622442]
    ]
  },
  {
    id: 'keio-plaza',
    displayName: {
      ja: '京王プラザホテル',
      en: 'Keio Plaza Hotel'
    },
    coords: [
      [35.690506893115696, 139.69401992520017],
      [35.6905417481003, 139.69422310251917],
      [35.68976108952693, 139.69432314579893],
      [35.68972850569897, 139.6941091380823]
    ]
  },
  {
    id: 'keio-plaza-south',
    displayName: {
      ja: '京王プラザホテル南館',
      en: 'Keio Plaza Hotel South Tower'
    },
    coords: [
      [35.68974908874757, 139.69446204307627],
      [35.689800173526805, 139.69476223688656],
      [35.689439096317365, 139.6948465770523],
      [35.68939381642336, 139.694550671725]
    ]
  },
  {
    id: 'mitsui-bldg',
    displayName: {
      ja: '新宿三井ビルディング',
      en: 'Shinjuku Mitsui Building'
    },
    coords: [
      [35.69206569006751, 139.69347318059377],
      [35.69218462935465, 139.69418084092746],
      [35.69175657908717, 139.6942737846555],
      [35.69164620518925, 139.69357009465404]
    ]
  },
  {
    id: 'sumitomo-bldg',
    displayName: {
      ja: '新宿住友ビルディング',
      en: 'Shinjuku Sumitomo Building'
    },
    coords: [
      [35.69148182455953, 139.69229179498964],
      [35.69156297030555, 139.69280879075924],
      [35.69143172110536, 139.6929596650061],
      [35.691014554157135, 139.69279739136763],
      [35.6909824224079, 139.69258281465903],
      [35.69131408677963, 139.69222541031957]
    ]
  },
  {
    id: 'center-bldg',
    displayName: {
      ja: '新宿センタービル',
      en: 'Shinjuku Center building'
    },
    coords: [
      [35.69189738523063, 139.69504707955096],
      [35.69200161342643, 139.6957581903729],
      [35.691575808911146, 139.69585461203508],
      [35.69147111893565, 139.69513955669856]
    ]
  },
  {
    id: 'kogakuin-univ',
    displayName: {
      ja: '工学院大学',
      en: 'Kogakuin University'
    },
    coords: [
      [35.69072588454362, 139.69520652217085],
      [35.69074766885095, 139.69563031116635],
      [35.69051130879835, 139.69564774552376],
      [35.69048625677016, 139.69522529763267]
    ]
  },
  {
    id: 'first-west',
    displayName: {
      ja: '新宿ファーストウエスト',
      en: 'Shinjuku First West Building'
    },
    coords: [
      [35.68987888117313, 139.695364033224],
      [35.68991809333844, 139.69590047499045],
      [35.68946933073882, 139.69592729707878],
      [35.689436653751876, 139.69540158414765]
    ]
  },
  {
    id: 'mode-gakuen',
    displayName: {
      ja: 'モード学園コクーンタワー',
      en: 'Mode Gakuen Cocoon Tower'
    },
    coords: [
      [35.69170182419286, 139.69673479881317],
      [35.691796584727975, 139.69691316570263],
      [35.691687664563695, 139.6971666344474],
      [35.69150631215752, 139.69717736328602],
      [35.69137669667355, 139.6969292589577],
      [35.69146165474532, 139.69674083378723]
    ]
  },
  {
    id: 'monolith-bldg',
    displayName: {
      ja: '新宿モノリスビル',
      en: 'Shinjuku Monolith Building'
    },
    coords: [
      [35.68873832414933, 139.6942400560414],
      [35.68885160530518, 139.6949481591731],
      [35.688524832302924, 139.69502594322924],
      [35.68840937218872, 139.6943178400975]
    ]
  },
  {
    id: 'kddi-bldg',
    displayName: {
      ja: 'KDDIビル',
      en: 'KDDI Building'
    },
    coords: [
      [35.688134444103056, 139.6944774331915],
      [35.68823247662406, 139.6950675191346],
      [35.68775538388829, 139.69519090074087],
      [35.6876529937512, 139.69459276817128]
    ]
  },
  {
    id: 'ns-bldg',
    displayName: {
      ja: 'NSビル',
      en: 'Shinjuku NS building'
    },
    coords: [
      [35.68840893486571, 139.69296198509093],
      [35.68853093045375, 139.69373177902577],
      [35.68776845496763, 139.6939168514352],
      [35.68764427969835, 139.69314437529152]
    ]
  },
  {
    id: 'metro-govt-1',
    displayName: {
      ja: '都庁第一本庁舎',
      en: 'Tokyo Metropolitan Government No.1 Bldg.'
    },
    coords: [
      [35.689948296308145, 139.6914297878742],
      [35.68999709201826, 139.69185785431702],
      [35.68908826701782, 139.69205311269445],
      [35.68903337121636, 139.69162504625163]
    ]
  },
  {
    id: 'metro-govt-2',
    displayName: {
      ja: '都庁第二本庁舎',
      en: 'Tokyo Metropolitan Government No.2 Bldg.'
    },
    coords: [
      [35.68835957222875, 139.69181243008327],
      [35.688411311483804, 139.69217050496238],
      [35.68766878322898, 139.6923350636201],
      [35.687616498861566, 139.6919763181748]
    ]
  },
  {
    id: 'metro-assembly',
    displayName: {
      ja: '東京都議会議事堂',
      en: 'Tokyo Metropolitan Assembly Bldg.'
    },
    coords: [
      [35.69011699914245, 139.6928618513898],
      [35.69016601419976, 139.69333995511414],
      [35.68935181571925, 139.69352033368423],
      [35.68929517551663, 139.69305295879522]
    ]
  },
  {
    id: 'washington-hotel',
    displayName: {
      ja: '新宿ワシントンホテル',
      en: 'Washington Hotel Shinjuku'
    },
    coords: [
      [35.68692938601706, 139.69258608081762],
      [35.687053017767845, 139.6933766619014],
      [35.68695280526261, 139.6934135422761],
      [35.68698276010053, 139.6936334834003],
      [35.686875467265466, 139.69366231714525],
      [35.68679976309703, 139.69312922813987],
      [35.68688799384756, 139.69303468027852],
      [35.686824439705404, 139.69262453025098]
    ]
  },
  {
    id: 'sonpo-japan-bldg',
    displayName: {
      ja: '損保ジャパン本社ビル',
      en: 'Sompo Japan Head Office Building'
    },
    coords: [
      [35.693020375122046, 139.69606921119592],
      [35.69292779441283, 139.6963910762558],
      [35.69236686192636, 139.6961563829827],
      [35.69245835409484, 139.69582915350517]
    ]
  },
  {
    id: 'nomura-bldg',
    displayName: {
      ja: '新宿野村ビルディング',
      en: 'Shinjuku Nomura Building'
    },
    coords: [
      [35.693274022915425, 139.69528445100696],
      [35.69317246375293, 139.6956429258464],
      [35.692727294564946, 139.69544701517836],
      [35.692837317599235, 139.6950885403389]
    ]
  },
  {
    id: 'l-tower',
    displayName: {
      ja: '新宿エルタワー',
      en: 'Shinjuku L Tower'
    },
    coords: [
      [35.69248820859542, 139.6970561372743],
      [35.6920508970236, 139.69767170423424],
      [35.692025845477296, 139.69709301765226]
    ]
  },
  {
    id: 'i-land-tower',
    displayName: {
      ja: '新宿アイランドタワー',
      en: 'Shinjuku i-Land Tower'
    },
    coords: [
      [35.69360697282112, 139.69318971649238],
      [35.69343651656706, 139.6935927183834],
      [35.692691792130475, 139.69311427298146],
      [35.69286467296534, 139.69270784185247]
    ]
  },
  {
    id: 'med-dent-univ-hosp',
    displayName: {
      ja: '東京医科歯科大学病院',
      en: 'Tokyo Medical and Dental University Hospital'
    },
    coords: [
      [35.694444145567964, 139.6919962159727],
      [35.69408536987995, 139.69286187998054],
      [35.693515546353545, 139.69250944266813],
      [35.69387960074321, 139.69164865105571]
    ]
  },
  {
    id: 'intl-bldg',
    displayName: {
      ja: '新宿国際ビルディング',
      en: 'Shinjuku International Building'
    },
    coords: [
      [35.69319202773061, 139.69080307717377],
      [35.69295319527564, 139.6914339011352],
      [35.69239553055502, 139.69125151093465],
      [35.692308395090215, 139.6904897636263]
    ]
  },
  {
    id: 'odakyu-daiichi-bldg',
    displayName: {
      ja: '小田急第一生命ビル',
      en: 'Odakyu Dai-Ichi-Life Building'
    },
    coords: [
      [35.69178310162284, 139.69076874790204],
      [35.691895756769306, 139.69143628692152],
      [35.69179366304959, 139.69146229493526],
      [35.69129199373145, 139.69103533004102],
      [35.69125854899828, 139.69088144929304]
    ]
  },
  {
    id: 'hyatt-regency',
    displayName: {
      ja: 'ハイアットリージェンシー東京',
      en: 'Hyatt Regency Tokyo'
    },
    coords: [
      [35.69119500800359, 139.69089771269296],
      [35.69121638935098, 139.69104744237646],
      [35.69090101389526, 139.6916348434375],
      [35.6907767342419, 139.69165952415455],
      [35.6906724995425, 139.69101124399248]
    ]
  },
  {
    id: 'park-hyatt',
    displayName: {
      ja: 'パークハイアット東京',
      en: 'Park Hyatt Tokyo'
    },
    coords: [
      [35.68592472075731, 139.6903194316291],
      [35.68598027407486, 139.6906775065254],
      [35.68505329112151, 139.6911924906515],
      [35.68499664786721, 139.6908290513323]
    ]
  },
  {
    id: 'oak-tower',
    displayName: {
      ja: '新宿オークタワー',
      en: 'Shinjuku Oak Tower'
    },
    coords: [
      [35.69426743015096, 139.6904374941747],
      [35.69404228075178, 139.69104219934428],
      [35.69357790810588, 139.6907355150628],
      [35.693822759472944, 139.69017412688245]
    ]
  },
  {
    id: 'nittochi-nishi-bldg',
    displayName: {
      ja: '日土地西新宿ビル',
      en: 'Nittochi Nishi-shinjuku Building'
    },
    coords: [
      [35.694755720736886, 139.69069739612698],
      [35.69456293778328, 139.6911929424837],
      [35.694257850130036, 139.6910015158418],
      [35.694438651693986, 139.69049994279018]
    ]
  },
  {
    id: 'nishi-ks-bldg',
    displayName: {
      ja: '西新宿KSビル',
      en: 'Nishi-shinjuku KS Building'
    },
    coords: [
      [35.68675260471759, 139.69102093032862],
      [35.68678637207336, 139.6912194137822],
      [35.68678364890002, 139.69132536103106],
      [35.68656525009337, 139.69137833465774],
      [35.68650751863903, 139.69129116287073],
      [35.68647320652845, 139.69109402052155]
    ]
  },
  {
    id: 'green-tower',
    displayName: {
      ja: 'GREEN TOWER',
      en: 'GREEN TOWER'
    },
    coords: [
      [35.692516912338135, 139.68929840642082],
      [35.692615484070124, 139.68989519790537],
      [35.69233447262515, 139.68996627644717],
      [35.692069798150996, 139.68989452735417],
      [35.691992863459745, 139.68942932030166],

    ]
  },
  {
    id: 'tower-la-tour',
    displayName: {
      ja: 'セントラルパーク タワー ラ・トゥール新宿',
      en: 'Central Park Tower La Tour Shinjuku'
    },
    coords: [
      [35.69255507088494, 139.68851183634254],
      [35.6926318587823, 139.68896043578368],
      [35.691894459794625, 139.68865403248347],
      [35.69182040413007, 139.6882029912732]
    ]
  }
];

_buildings.h = [
  {
    id: 'ariga',
    displayName: {
      ja: 'アリガ',
      en: 'Ariga'
    },
    coords: [
      [35.707016339019624, 139.6909476607242],
      [35.7070320252185, 139.6910635668817],
      [35.70694255574652, 139.69107930722407],
      [35.706928612443136, 139.69095982371604]
    ]
  },
  {
    id: 'hoyo',
    displayName: {
      ja: 'ホーヨー',
      en: 'Hoyo'
    },
    coords: [
      [35.70676452706449, 139.69114833757826],
      [35.70671831439611, 139.69133487954585],
      [35.70660149892052, 139.69129061535014],
      [35.706648995343336, 139.6911072351108]
    ]
  },
  {
    id: 'pearl',
    displyName: {
      ja: 'パール',
      en: 'Pearl'
    },
    coords: [
      [35.7066931514802, 139.6908175267356],
      [35.7066628757498, 139.6909753916081],
      [35.70658815262129, 139.6909230344142],
      [35.706613275060256, 139.69079134813865]
    ]
  },
  {
    id: 'granduo',
    displayName: {
      ja: 'グランデュオ',
      en: 'Granduo'
    },
    coords: [
      [35.70679032043312, 139.69066610118068],
      [35.706828435287576, 139.69084849138127],
      [35.70674567158043, 139.69082636315844],
      [35.706747849573816, 139.690673477255]
    ]
  }
];

_buildings.o = [
  {
    id: 'ohara-coop',
    displayName: {
      ja: '大原コーポ',
      en: 'Ohara Coop.'
    },
    coords: [
      [35.66861819801034, 139.66027359538703],
      [35.66863018270009, 139.66035808496522],
      [35.66849508245705, 139.66038490705358],
      [35.6684841872662, 139.66029907637093]
    ]
  },
  {
    id: 'park-maison',
    displayName: {
      ja: 'パークメゾン',
      en: 'Park Maison'
    },
    coords: [
      [35.66816865257446, 139.66003231931265],
      [35.66817954780838, 139.66016508864982],
      [35.66809347541989, 139.6601778291418],
      [35.66808149064958, 139.66004304814797]
    ]
  },
  {
    id: 'riwa-heights',
    displayName: {
      ja: 'リワハイツ',
      en: 'Riwa Heights'
    },
    coords: [
      [35.66812927738119, 139.66022875760333],
      [35.668132545953135, 139.66036622080597],
      [35.66805410018954, 139.66036890301478],
      [35.66805083161439, 139.66023009870773]
    ]
  },
  {
    id: 'setsuto-reve',
    displayName: {
      ja: 'セツトレーヴ',
      en: 'Setsuto reve'
    },
    coords: [
      [35.668475917246624, 139.66068326182014],
      [35.66849607335062, 139.66078116244253],
      [35.668425799344355, 139.66080262011317],
      [35.66841326986389, 139.6607462937277],
      [35.66839420325938, 139.66075232869758],
      [35.66838548709581, 139.6607114250129]
    ]
  }  
];


_buildings.s = [
  {
    id: 'area-15',
    displayName: {
      ja: '15番地',
      en: 'Area 15'
    },
    coords: [
      [35.614584697655495, 139.52020879727687],
      [35.61424194813778, 139.52084432181832],
      [35.61397234595802, 139.52070376287958],
      [35.61360726629272, 139.52024094951062],
      [35.61398435328989, 139.5198549649257],
      [35.61429679546066, 139.52029064709305]
    ]
  },
  {
    id: 'sun-hills-a',
    displayName: {
      ja: 'サンヒルズA棟',
      en: 'Sun Hills A Bldg.'
    },
    coords: [
      [35.61509386600335, 139.52079625527028],
      [35.615027424321234, 139.52090941896924],
      [35.61477017523356, 139.52072500406106],
      [35.6148391725858, 139.52061184036208]
    ]
  },
  {
    id: 'stella-home',
    displayName: {
      ja: 'ステラホーム',
      en: 'Stella Home'
    },
    coords: [
      [35.61527754618062, 139.52159795094542],
      [35.61510330057417, 139.52201437975955],
      [35.61472493737557, 139.52176023570405],
      [35.614914119196925, 139.52135758578453]
    ]
  },
  {
    id: 'hydence',
    displayName: {
      ja: 'ハイデンス',
      en: 'Hydence'
    },
    coords: [
      [35.61453332962845, 139.52250828299788],
      [35.61449189992715, 139.52264172288727],
      [35.61383719876203, 139.5223325983109],
      [35.61387917393437, 139.52219178234722],
      [35.61395113132255, 139.52222598050466],
      [35.61404543901401, 139.52192356144846],
      [35.61416100666697, 139.52197921728174],
      [35.61406397346017, 139.5222843185364]
    ]
  }  
];

// console.log(_buildings.shinjuku.map(({ id }) => id).join('\n'));
// console.log(_buildings.shinjuku.map(({coords}) => average(coords)).join('\n'));

export const buildings = _buildings[place];
