import * as PIXI from 'pixi.js';
import 'the-new-css-reset/css/reset.css';
import GNSS from '../modules/gnss';
import Detector from '../modules/building-detector/detector';
import Preview from '../modules/building-detector/preview';
import Fullscreen from '../modules/fullscreen';
import SBAudio from '../modules/audio';
import app from '../modules/canvas';
import Video from '../modules/camera/video';
import fragment from './shader.frag';
import bgFragment from './bgShader.frag';
import {gsap} from "gsap";
import { getURLParam, radToDeg } from '../modules/util';


const DEBUG_MODE = !!getURLParam('debug');
// const DEBUG_MODE = true;

Detector.setDebugMode(DEBUG_MODE);
Preview.setDebugMode(DEBUG_MODE);

const sleep = (t) => new Promise(resolve => {
  window.setTimeout(() => {
    resolve();
  }, t)
});

// ----------------------------------------------
// Common Variables
// ----------------------------------------------
let LANGUAGE = "ja"; // 選択されている言語の情報
let width = app.screen.width;　
let height = app.screen.height;
let appCenterX = width / 2;　　//画面のセンター位置X
let appCenterY = height / 2;　//画面のセンター位置Y
let windowAspect = width / height; // アスペクト比率
const specSprites = [];

const mouseposition = app.renderer.plugins.interaction.mouse.global; // マウス座標
let animFocusingSize = {value: height,
                        value2: height};　// フォーカス時の円の大きさ
const videoSprite = PIXI.Sprite.from(Video.element);　
videoSprite.anchor.set(0.5);
let nowPos = [0,0]; // 現在の座標情報

let baseSize;
// ----------------------------------------------
// -------------------------------
//　Viewサイズ、Ratioセットアップ
// ------------------------------

const switchVideo = async () => {
  await Video.switchVideo();
  await resizeView();
}

// リサイズ処理
const resizeView = async () => {
  if (!Video.settings) {
    return;
  }

  await sleep(1000);

  console.log(Video.settings);

  const viewSize = [
    app.renderer.width / app.renderer.resolution,
    app.renderer.height / app.renderer.resolution
  ];

  const aspectRatio = {
    view: viewSize[0] / viewSize[1],
    video: Video.settings.width / Video.settings.height
  };
  // console.log('aspectRatio', aspectRatio);

  if (aspectRatio.view < aspectRatio.video) {
    width = viewSize[1] * aspectRatio.video;
    height = viewSize[1];
    windowAspect  = aspectRatio.video;


  } else {
    width = viewSize[0];
    height = viewSize[0] / aspectRatio.video;
    windowAspect = aspectRatio.view;
  }

  videoSprite.width = width;
  videoSprite.height = height;
  specLayerFilter.uniforms.size = [width, height];
  bgFilter.uniforms.size = [width, height];


  // リサイズ時の変数の更新
  width = app.screen.width;
  height = app.screen.height;
  appCenterX = width / 2;
  appCenterY = height / 2;
  videoSprite.x = width / 2;
  videoSprite.y = height / 2;

  animFocusingSize = {value: height,
                          value2: height};
  if(width>height){
    baseSize = width*0.5;
  }else{
    baseSize = width;
  }

  // resize compass size
  compassSize = width*0.4;
  // console.log('width',   width );
  // console.log('baseSize', baseSize);


  //resize focus video
  focusVideo.x = appCenterX;
  focusVideo.y = appCenterY;
  focusVideo.width = viewSize[0];
  focusVideo.height = viewSize[1];

  //resize specLayer
  for(let i = 0; i< specSprites.length; i++){
    specSprites[i].x = appCenterX;
    specSprites[i].y = appCenterY;
    // specSprites[i].width = baseSize*0.75;
    // specSprites[i].height = baseSize*0.75;
  }

  //リサイズinfoGraph
  let infoGraph0Aspect= 60/272;
  infoGraph0.x = appCenterX;
  infoGraph0.y = appCenterY;
  infoGraph0.width =(compassSize*1.2);
  infoGraph0.height = (compassSize*1.2)*infoGraph0Aspect;
  infoGraph1.x = appCenterX;
  infoGraph1.y = appCenterY;
  infoGraph1.width =(compassSize*0.6);
  infoGraph1.height = (compassSize*0.6);


  //リサイズbuildingNameText
  if(width<height){
    buildingNameText.x = appCenterX;
    buildingNameText.y = appCenterY+appCenterY*0.7;
    buildingNameTextEn.x = appCenterX;
    buildingNameTextEn.y = appCenterY+appCenterY*0.7;
  }else{
    buildingNameText.x = appCenterX;
    buildingNameText.y = appCenterY+appCenterY*0.8;
    buildingNameTextEn.x = appCenterX;
    buildingNameTextEn.y = appCenterY+appCenterY*0.8;
  }


  //リサイズ infoWarnningLocation
  infoWarnningLocation.x = appCenterX;
  infoWarnningLocation.y = appCenterY;
  infoWarnningLocation.width = compassSize;
  infoWarnningLocation.height = compassSize;

  //リサイズcompassGraph
  compassGraph.clear();
  compassGraph.lineStyle(1, 0xFFFFFF, 1);
  compassGraph.drawCircle(appCenterX,appCenterY,compassSize,compassSize);

  //リサイズ waveGraph
  waveGraph.clear();
  waveGraph.x = appCenterX;
  waveGraph.y = appCenterY;
  waveGraph.lineStyle(1, 0xFFFFFF, 1);
  waveGraph.drawCircle(0, 0, compassSize, compassSize);

};

// リサイズ時のイベント
app.renderer.addListener('resize', async (w, h) => {
  await resizeView();
});

// デバイスの向き判定処理
window.addEventListener('orientationchange', async function() {
  console.log('orientation changed.');
  await Video.reopen();
  await resizeView();
  // window.alert("端末の向きが " + screen.orientation.angle + "になりました。");
});
// ---------------------------------------------------



//
// ----------------------------------------------
// ビル検出時にフォーカスした先に映るビルのレイヤー
const focusVideo = PIXI.Sprite.from(Video.element);
focusVideo.anchor.set(0.5);


const maskGraph = new PIXI.Graphics();
focusVideo.mask　= maskGraph;

const focusLayer = new PIXI.Container();
focusLayer.addChild(focusVideo);

// ビデオフィルター
const specLayerFilter = new PIXI.Filter(PIXI.defaultVertex, fragment, { size: [0, 0], amplitudes: 0.0, iTime: 0.0});
const bgFilter = new PIXI.Filter(PIXI.defaultVertex, bgFragment, { size: [0, 0], amplitudes: 0.0, iTime: 0.0, iBlend: 1.0, iRotation: 0.0});
// -------------

//　背景に出すようのカメラレイヤー
const bgCameraLayer = new PIXI.Container();
bgCameraLayer.addChild(videoSprite);

// --------------
// スペクトラム表示用のレイヤーの追加と登録
const specLayer =  new PIXI.Container();
// スペクトラム用の素材の読み込み
let specImages = [
  PIXI.Texture.from('img/img_spec_1.png'),
  PIXI.Texture.from('img/img_spec_2.png'),
  PIXI.Texture.from('img/img_spec_3.png'),
  PIXI.Texture.from('img/img_spec_7.png'),
  PIXI.Texture.from('img/img_spec_6.png'),
  PIXI.Texture.from('img/img_spec_5.png'),
  PIXI.Texture.from('img/img_spec_4.png')
];

// const specSprites = [];
for(let i = 0; i< specImages.length; i++){
  specSprites[i] = new PIXI.Sprite.from(specImages[i]);
  specSprites[i].anchor.x = 0.44;
  specSprites[i].anchor.y = 0.5;
  specLayer.addChild(specSprites[i]);
}

// コンパスレイヤー表示用のレイヤーの追加と登録
const compassLayer = new PIXI.Container();
let compassSize = width*0.4;
const compassGraph = new PIXI.Graphics();

//探索モード時の波形表示用
const waveGraph = new PIXI.Graphics();
compassLayer.addChild(compassGraph);
compassLayer.addChild(waveGraph);

//コンパスレーダー用の描画領域
let laderGraph = new PIXI.Graphics();
compassLayer.addChild(laderGraph);


// インフォメーションレイヤー
const infoLayer = new PIXI.Container();
let infoTexture = [PIXI.Texture.from('img/img_moving.png'),
                   PIXI.Texture.from('img/img_smartphone.png'),
                   PIXI.Texture.from('img/img_warning_location.png')
                  ];

const infoGraph0 = new PIXI.Sprite.from(infoTexture[0]);
infoGraph0.anchor.set(0.5);
infoGraph0.alpha = 0;


const infoGraph1 = new PIXI.Sprite.from(infoTexture[1]);
infoGraph1.anchor.set(0.5);
infoGraph1.alpha = 0;


const infoWarnningLocation = new PIXI.Sprite.from(infoTexture[2]);
infoWarnningLocation.anchor.set(0.5);
infoWarnningLocation.alpha = 0;
// infoWarnningLocation.width = compassSize;
// infoWarnningLocation.height = compassSize;

infoLayer.addChild(infoGraph0);
infoLayer.addChild(infoGraph1);
infoLayer.addChild(infoWarnningLocation);


// テキストレイヤー
const textLayer = new PIXI.Container();
// const textStyle = new PIXI.TextStyle();

const buildingNameText = new PIXI.Text('Sounding Building',{
    fill: "#fcfcfc",
    fontFamily: "NotoSansJP-Regular",
    align: 'center',
    fontSize: 20
});
buildingNameText.alpha = 0;
buildingNameText.anchor.set(0.5);

const buildingNameTextEn = new PIXI.Text('Sounding Building',{
    fill: "#fcfcfc",
    fontFamily: "NotoSansJP-Regular",
    align: 'center',
    fontSize: 14
});
buildingNameTextEn.alpha = 0;
buildingNameTextEn.anchor.set(0.5);

textLayer.addChild(buildingNameText);
textLayer.addChild(buildingNameTextEn);
// =================
// -------------
// アニメーション系
// -------------
//

//フォーカスアニメーション
const animFocusing = function(_size,_size2){

  if(height > width){
    focusVideo.width= _size*windowAspect;
    focusVideo.height= _size;

    maskGraph.clear();
    maskGraph.beginFill();
    maskGraph.drawCircle(appCenterX, appCenterY, _size2,_size2);
    maskGraph.endFill();
  }else{
    focusVideo.width= _size;
    focusVideo.height= _size;

    maskGraph.clear();
    maskGraph.beginFill();
    maskGraph.drawCircle(appCenterX, appCenterY, _size2,_size2);
    maskGraph.endFill();
  }

}

const animLader = function(){
  //
  // 魚群レーダー風のアニメーション
  //
  if(DEBUG_MODE){ // PC debug用
    nowPos = [35.68914393975705, 139.69100791083198]; // 適当な値を入れておく
  }

  laderGraph.clear();
  for(let i=0;i<Detector.buildings.length;i++){
      let pos = Detector.buildings[i].center;

      let distanceUserFromAreaX = (pos[0]-nowPos[0])*50000;
      let distanceUserFromAreaY = (pos[1]-nowPos[1])*50000;
      laderGraph.beginFill(0xFFFFFF);
      laderGraph.drawCircle(distanceUserFromAreaX, distanceUserFromAreaY, 10, 10);
      laderGraph.endFill();
      document.getElementById('debug').textContent = distanceUserFromAreaX;
  }

  laderGraph.beginFill(0x31B4D0);
  laderGraph.drawCircle(0, 0, 10, 10);
  laderGraph.endFill();

  laderGraph.x = appCenterX;
  laderGraph.y = appCenterY;
  const maskedGraph = new PIXI.Graphics();
  maskedGraph.clear();
  maskedGraph.beginFill();
  maskedGraph.drawCircle(appCenterX, appCenterY, compassSize);
  maskedGraph.endFill();
  laderGraph.mask = maskedGraph;
};


let tickTimer = 0;
let waveSize =0;
// UPDATE処理
app.ticker.add((delta) => {
  animFocusing(animFocusingSize.value, animFocusingSize.value2);

  if(SBAudio.startAudio){
    let amplitudes = SBAudio.getAmplitudes();
    let spectrum = new SBAudio.getSpectrum();
    let keys = Object.keys(amplitudes);

    if(waveSize>width){
      waveSize =0;
    }else{
      waveSize+= 3;
    }
    waveGraph.width = compassSize*2 + waveSize;
    waveGraph.height= compassSize*2 + waveSize;
    waveGraph.alpha = 1 - waveSize/width
    // console.log(spectrum);
    // console.log(spectrum[1]/255*width*.05);
    for(let i = 0; i< specSprites.length; i++){
      let inv = 1;
        if(i%2==0){
          inv *= -1;
        }
      specSprites[i].rotation += delta*0.005*i*inv;
      specSprites[i].width = baseSize*0.4 + (spectrum[i]/255)*baseSize*.55;
      specSprites[i].height = baseSize*0.4 + (spectrum[i]/255)*baseSize*.55;
    }
    specLayerFilter.uniforms.size = [width, height];
    specLayerFilter.uniforms.amplitudes = spectrum[1]/255;
    specLayerFilter.uniforms.iTime = tickTimer*0.001;

    bgFilter.uniforms.size = [width, height];
    bgFilter.uniforms.amplitudes = spectrum[1]/255;
    bgFilter.uniforms.iTime = tickTimer*0.001;
    // console.log(amplitudes[keys[0]]*100);
  }
  tickTimer++;
  animLader();
});


//---------------------------//
//　制御系
//---------------------------//
const loadingView = function (){


};

// タイムラインの宣言
let tl_focusSize = gsap.timeline();
let tl_InfoGraph = gsap.timeline();
let tl_laderLayer = gsap.timeline();
let tl_buildingNameText = gsap.timeline();

//インフォメーションレイヤーを描画アニメーション
animFindingFadeIn = function(){
  tl_InfoGraph.clear();
  tl_InfoGraph
  .to(infoGraph0, { alpha: 0 , duration: 5})
  .to(infoGraph1, { alpha: 0 , duration: 5},"<")
  .to(infoGraph0, { alpha: 1 , duration: 1})
  .to(infoGraph1, { alpha: 1 , duration: 1},"<")
  .to(infoGraph1, { x: appCenterX                       , duration: 0 },"<")
  .to(infoGraph1, { x: appCenterX +width*0.03, duration: 1,  delay: 0.1 })
  .to(infoGraph1, { x: appCenterX -width*0.03, duration: 2,  delay: 0.0 })
  .to(infoGraph1, { x: appCenterX +width*0.03, duration: 2,  delay: 0.0 })
  .to(infoGraph1, { x: appCenterX -width*0.03, duration: 2,  delay: 0.0 })
  .to(infoGraph1, { x: appCenterX                       , duration: 1})
  .to(infoGraph0, { alpha: 0 , duration: 1})
  .to(infoGraph1, { alpha: 0 , duration: 1},"<");
};

animLaderFadeIn = function(){
  //レーダーレイヤーをフェードイン
  tl_laderLayer.clear();
  tl_laderLayer.to(compassGraph, {alpha: 1,duration: 3})
  .to(waveGraph, {alpha: 1,duration: 3},"<")
  .to(laderGraph, {alpha: 1,duration: 3},"<");
}

//------------------
//ビル探索モードの時
//-----------------
const findingView = function(){
  //激しすぎる切り替えを回避？
  // if(animFocusingSize.value!=appCenterY){
  //   return;
  // }

  //フォーカスサイズを元に戻す。
  tl_focusSize.clear();

  if(height>width){
    tl_focusSize.to(animFocusingSize, { value: height, duration: 0.5})
    .to(animFocusingSize, { value2: height, duration: 1.2},"<")
    .set(videoSprite, {filters: []});//ビデオフィルターの解除s
  }else{
    tl_focusSize.to(animFocusingSize, { value: width, duration: 0.5})
    .to(animFocusingSize, { value2: width, duration: 1.2},"<")
    .set(videoSprite, {filters: []});//ビデオフィルターの解除
  }

  //レーダーレイヤーをフェードイン
  animLaderFadeIn();


  //インフォメーションレイヤーを描画アニメーション
  animFindingFadeIn();

  //　ビル名称の表示アニメーションをフェードアウト
  tl_buildingNameText
  .to(buildingNameText,{alpha:0, duration: 0.2})
  .to(buildingNameTextEn,{alpha:0, duration:0.2},"<");

  focusLayer.filters = [bgFilter];
  if(Detector.outOfArea){ //エリア外だった時の処理
    tl_InfoGraph.clear();
    tl_InfoGraph.to(infoGraph0, { alpha: 0 , duration: 1})
    .to(infoGraph1, { alpha: 0 , duration: 1},"<")
    .to(infoWarnningLocation, { alpha: 1, duration: 1});
    return;
  }
};


//------------------
//ビル検出モードの時
//-----------------
const detectingView = function(){

  // //激しすぎるアニメーションを回避
  // if(animFocusingSize.value!=height)return;

  //レーダー表示をフェードアウト
  tl_laderLayer.clear();
  tl_laderLayer.to(compassGraph, {alpha: 0,duration: 1})
  .to(waveGraph, {alpha: 0,duration: 1},"<")
  .to(laderGraph, {alpha: 0,duration: 1},"<");

  // インフォメーション表示をフェードアウト
  tl_InfoGraph.clear();
  tl_InfoGraph
  .to(infoWarnningLocation, { alpha: 0 , duration: 0.1})
  .to(infoGraph0, { alpha: 0 , duration: 0.1},"<")
  .to(infoGraph1, { alpha: 0 , duration: 0.1},"<");

  //フィルターを背景ビデオに適応
  videoSprite.filters = [specLayerFilter];


  // フォーカスアニメーションを開始

  tl_focusSize.clear();
  tl_focusSize
  .to(animFocusingSize, { value: baseSize, duration: 2})
  .to(animFocusingSize, { value2: baseSize*0.3, duration: 0.3},"<");

  // ビルの名称を表示アニメーション
  tl_buildingNameText.clear();
  tl_buildingNameText
  .to(buildingNameText,{alpha:1, duration: 1})
  .to(buildingNameTextEn,{alpha:1, duration: 1},"<");



  //debug用
  if(!Detector.currentHit){
    return;
  }

  //テキストをビルの名前に変更


  if(LANGUAGE == "ja"){
    buildingNameText.text = Detector.currentHit.displayName.ja;
    buildingNameTextEn.text = "\n \n \n"+ Detector.currentHit.displayName.en;
  }else if(LANGUAGE == "en"){
    buildingNameText.text = Detector.currentHit.displayName.en;
  }

};

//ビル探索状態によるモードの切り替え
const switchingMode = function(){
  if(Detector.currentHit){
    detectingView();
    console.log("--> Detecting Building::: " + Detector.currentHit.displayName.ja);
  }else{
    findingView();
  }
};


// =================
// View Controller
// ================

// 各レイヤーを登録
const initView = function(){
  app.stage.addChild(bgCameraLayer);
  app.stage.addChild(specLayer);
  app.stage.addChild(focusLayer);
  app.stage.addChild(infoLayer);
  app.stage.addChild(compassLayer);
  app.stage.addChild(textLayer);

  // ビル探索ビューを表示する。
  findingView();
  animFindingFadeIn();
  animLaderFadeIn();


}


// #####################################
if (typeof window.SB === 'undefined') {
  window.SB = {};
}

const SBVideo = {
  switch: switchVideo
};

// export default SBVideo;
// window.SB.Video = SBVideo;
// 現在検出しているビル名 → Detector.currentHit で取得

// ----------------------------------


Object.entries({
  'btn_start': () => {
    document.getElementById('btn_start').style.display = 'none';
    let PositionInput;
    if (DEBUG_MODE) {
      PositionInput = Preview;

    } else {
      PositionInput = GNSS;
      GNSS.init();
    }
    PositionInput.on('positionUpdated', coord => {
      // console.log('coord', coord);
      coord = [coord.latitude, coord.longitude];
      Detector.setPlayerCoord(coord);
      SBAudio.updateUserCoord(coord);
      nowPos = coord;

    });
    PositionInput.on('directionUpdated', (direction, tilt)  => {
      // console.log('direction', direction);
      document.getElementById('debug').textContent =
        `angle: ${direction.toFixed(3)} ${radToDeg(direction).toFixed(1)} nowPos: ${nowPos}`;
      direction = direction * -1 + Math.PI * 0.5;
      Detector.setPlayerDirection(direction, tilt);
      SBAudio.updateUserDirection(direction, Detector.currentHit);
      laderGraph.rotation =   direction+Math.PI*25;
      switchingMode();

      //tilt判定
      if(tilt < 30 && tilt > - 30){
        let tl_bgFilter= gsap.timeline();
        tl_bgFilter.clear();
        tl_bgFilter
        .to(bgFilter.uniforms, {iBlend:0.8, duration: 0.2})
        .set(focusLayer, {filters:[bgFilter]});

      }else{
        let tl_bgFilter= gsap.timeline();
        tl_bgFilter.clear();
        tl_bgFilter.to(bgFilter.uniforms, {iBlend:0, duration: 0.5})
        .set(focusLayer,{filters:[]});
      }
      bgFilter.uniforms.iRotation = direction+Math.PI*25;
    });
    SBAudio.startAudio();
    SBVideo.switch();
    initView(); // init


  },
  'btn-undetect-switch': () => findingView(),
  'btn-detect-switch': () => detectingView(),
  'btn-camera-switch': () => SBVideo.switch(),
  'btn-preview-toggle': () => Preview.toggleDisplay(),
  'btn-fullscreen': () => Fullscreen.toggle(document.body)
}).forEach(([id, func]) => {
  document.getElementById(id).addEventListener('click', func);
});
