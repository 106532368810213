import * as PIXI from 'pixi.js';
import { osName } from '../util';

const container = document.createElement('div');
container.style.position = 'fixed';
container.style.width = osName === 'ios' ? '100dvw' : '100vw';
container.style.height = osName === 'ios' ? '100dvh' : '100vh';
container.style.zIndex = '-100';
container.style.left = '0px';
container.style.top = '0px';
document.body.appendChild(container);

const app = new PIXI.Application({
  resolution: window.devicePixelRatio || 1,
  autoDensity: true,
  backgroundColor: 0x000000,
  resizeTo: container,
  antialias: true
});

for (const [k, v] of Object.entries({
  position: 'absolute',
  left: '0px',
  top: '0px',
  zIndex: '-100'
})) {
  app.view.style[k] = v;
}
document.body.appendChild(app.view);

export default app;
