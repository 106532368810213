let isFullscreen = false;

const start = target => {

  if (target.webkitRequestFullscreen) {
    target.webkitRequestFullscreen(); //Chrome15+, Safari5.1+, Opera15+
  } else if (target.mozRequestFullScreen) {
    target.mozRequestFullScreen(); //FF10+
  } else if (target.msRequestFullscreen) {
    target.msRequestFullscreen(); //IE11+
  } else if (target.requestFullscreen) {
    target.requestFullscreen(); // HTML5 Fullscreen API仕様
  }

};

const exit = () => {

  if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen(); //Chrome15+, Safari5.1+, Opera15+
  } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen(); //FF10+
  } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); //IE11+
  } else if(document.cancelFullScreen) {
      document.cancelFullScreen(); //Gecko:FullScreenAPI仕様
  } else if(document.exitFullscreen) {
      document.exitFullscreen(); // HTML5 Fullscreen API仕様
  }

};

const exitHandler = () => {
  isFullscreen = !!(
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement
  );
}

document.addEventListener('fullscreenchange', exitHandler, false);
document.addEventListener('mozfullscreenchange', exitHandler, false);
document.addEventListener('MSFullscreenChange', exitHandler, false);
document.addEventListener('webkitfullscreenchange', exitHandler, false);

const toggle = target => {
  (isFullscreen ? exit : start)(target);
};

// #####################################
if (typeof window.SB === 'undefined') {
  window.SB = {};
}
const Fullscreen = {
  start,
  exit,
  toggle,
  get isFullscreen() {
    return isFullscreen;
  }
};

export default Fullscreen;
window.SB.Fullscreen = Fullscreen;

// TODO:
// - iOS