export const scaleValue = (x, a0, a1, b0, b1) =>
  (x - a0) / (a1 - a0) * (b1 - b0) + b0;

export const degToRad = deg => deg * (Math.PI / 180);
export const radToDeg = rad => rad / Math.PI * 180;

export const normalizeRadians = rad => {
  while (rad < 0) {
    rad += Math.PI * 2;
  }
  return rad % (Math.PI * 2);
};

export const average = array => (
  array
    .reduce((a, b) => [a[0] + b[0], a[1] + b[1]], [0, 0])
    .map(v => v / array.length)
  );

export const osName = (() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return (
    /windows phone/i.test(userAgent) ? 'windows-phone' :
    /android/i.test(userAgent) ? 'android' :
    /iPad|iPhone|iPod/.test(userAgent) ? 'ios' :
    null
  );
})();

export const loadAudioBuffer = (audioContext, url) => new Promise((resolve, reject) => {
  window
    .fetch(url)
    .then(res => res.arrayBuffer())
    .then(arrayBuffer => {
      // const t = performance.now();
      audioContext.decodeAudioData(
        arrayBuffer,
        buffer => {
          // const et = performance.now() - t;
          // const el = document.createElement('div');
          // el.textContent = et;
          // document.body.appendChild(el);
          resolve(buffer)
        },
        reject
      )
    })
    .catch(reject)
});

export const getURLParam = key => {
  return (new URL(document.location)).searchParams.get(key);
};

export class MovingAverage {
  constructor(n) {
    this.array = new Array(n).fill(0);
    this.sum = 0;
    this.n = n;
  }
  push(v) {
    this.array.push(v);
    const sub = this.array.shift();
    this.sum = this.sum + v - sub;
    return this.sum / this.n;
  }
}