import { osName, loadAudioBuffer } from '../util';
import { EventEmitter } from 'events';

const CHUNK_LENGTH_SEC = 5;
const PRELOAD_SEC = 5;
const EXTENSION = osName === 'ios' ? 'caf' : 'opus';
const FPS = 60;

class Clock extends EventEmitter {
  constructor(audioContext, bpm) {
    super();
    this.audioContext = audioContext;
    this.bpm = bpm;
    this.beatSec = 60 / bpm;
  }
  start() {
    this.startTime = this.audioContext.currentTime;
    // TODO: 負荷分散でずらす
    window.setInterval(() => this.update(), 1000);
  }
  update() {
    const { currentTime } = this.audioContext;
    const elapsedTime = currentTime - this.startTime;
    const currentBeat = elapsedTime / this.beatSec;
    this.emit('beatUpdated', currentBeat);
  }
  beatToSec(beat) {
    return beat * this.beatSec;
  }
  getElapsedTime() {
    return this.audioContext.currentTime - (this.startTime || 0);
  }
};

class Track {
  constructor(audioContext, clock, baseUrl, nFiles, loopBeats, output) {
    this.audioContext = audioContext;
    this.clock = clock;
    this.tick = 0;
    this.loopStartBeat = null;
    this.queue = [];
    this.baseUrl = baseUrl;
    this.nFiles = nFiles;
    this.loopBeats = loopBeats;
    this.loopTime = this.clock.beatToSec(this.loopBeats);
    this.output = output;
    this.active = false;
  }
  async createSource(url, startTime) {
    const { audioContext } = this;
    const source = audioContext.createBufferSource();
    source.buffer = await loadAudioBuffer(audioContext, url);
    source.connect(this.output);
    source.start(
      startTime,
      audioContext.currentTime > startTime ? (audioContext.currentTime - startTime) : 0
    );
    source.addEventListener('ended', () => {
      source.buffer = null;
      source.disconnect();
      // console.log('ended');
    });
    return source;
  }
  async enqueueChunks(loopStartBeat) {
    // TODO: inactiveでは再生しないように
    const loopStartSec = this.clock.beatToSec(loopStartBeat);
    console.log(this.baseUrl, loopStartSec, this.nFiles);
    for (let i = 0; i < this.nFiles; i += 1) {
      const url = `${this.baseUrl}/chunk${(i + 1).toString().padStart(3, '0')}.${EXTENSION}`;
      const startTime = loopStartSec + CHUNK_LENGTH_SEC * i;
      const preloadTime = startTime - PRELOAD_SEC;
      this.queue.push({
        url,
        startTime,
        preloadTime
      });
    }
    // console.log(this.queue);
  }
  async checkChunks(beat) {
    const sec = this.clock.beatToSec(beat);
    for (const q of this.queue) {
      if (q.startTime + CHUNK_LENGTH_SEC < sec) {
        q.ended = true;
      }
    }
    this.queue = this.queue.filter(({ ended }) => !ended);

    if (this.active) {
      for (const q of this.queue) {
        if (!q.source && q.preloadTime < sec) {
          console.log('@createSource');
          q.source = await this.createSource(q.url, q.startTime);
        }
      }
    }

  }
  onBeatUpdated(beat) {
    const { loopBeats } = this;

    // 初回
    if (this.loopStartBeat === null) {
      this.loopStartBeat = Math.floor(beat / loopBeats) * loopBeats;
      this.enqueueChunks(this.loopStartBeat);
      // console.log(this.info.label, beat, this.loopStartBeat);
    }

    // 現在のループが始まったら、次のループを用意する
    if (beat > this.loopStartBeat) {
      this.loopStartBeat = (Math.floor(beat / loopBeats) + 1) * loopBeats;
      this.enqueueChunks(this.loopStartBeat);
      // console.log(this.info.label, beat, this.loopStartBeat);
    }

    this.checkChunks(beat);
    // console.log(this.info.label, this.queue.length, audioContext.currentTime);
  }
  setActive(active) {
    this.active = active;
  }
  getCurrentFrame() {
    return ((this.clock.getElapsedTime() % this.loopTime) * FPS) | 0;
  }
}

class LoopTracker {
  constructor(audioContext, bpm) {
    this.audioContext = audioContext;
    this.clock = new Clock(audioContext, bpm);
    this.started = false;
  }
  start() {
    this.clock.start();
    this.started = true;
  }
  createTrack(...args) {
    const track = new Track(this.audioContext, this.clock, ...args);
    this.clock.on('beatUpdated', beat => track.onBeatUpdated(beat));
    this.track = track;
    return track;
  }
}

export default LoopTracker;