const getUserMedia = (
  navigator.mediaDevices?.getUserMedia.bind(navigator.mediaDevices) ||
  navigator.webkitGetUserMedia ||
  navigator.getUserMedia ||
  navigator.mozGetUserMedia
).bind(navigator);

const videoDevices = [];

const videoElement = (() => {

  const videoElement = document.createElement('video');

  // Note: iOS対応用
  videoElement.setAttribute('muted', '');
  videoElement.setAttribute('playsinline', '');
  
  // Note: 非表示にしてしまうとストリームが止まってしまう場合があるため、
  // 見えない位置にずらすことで対処している。
  videoElement.style.position = 'absolute';
  videoElement.style.left = '-10000px';
  
  document.body.appendChild(videoElement);
  return videoElement;

})();

const listUpDevices = async () => {

  const devices = await navigator.mediaDevices.enumerateDevices();
  for (let d of devices) {
    if (d.kind === 'videoinput' && d.deviceId) {
      console.log(d);
      videoDevices.push(d);
    }
  }

};

let initialized = false;
let deviceIndex = 0;

const open = async () => {

  if (!initialized) {

    // Note: iOSでは一度getUserMediaをトリガーしないとデバイスリストが取れない
    // ので、ダミーで一回呼んでおく。
    const stream = await getUserMedia({ video: true });
    if (stream) {
      for (const track of stream.getTracks()) {
        track.stop();
      }
    }

    await listUpDevices();

    // Note: Backカメラを見つける
    const backCamera =
      videoDevices.find(({ label = '' }) => label.toLowerCase().indexOf('back') >= 0);
    if (backCamera) {
      deviceIndex = videoDevices.findIndex(camera => camera === backCamera);
    }

    initialized = true;

  }

  if (videoDevices.length <= 0) {
    throw new Error('video: no device available.');
  }

  deviceIndex = Math.max(Math.min(deviceIndex, videoDevices.length - 1), 0);
  const device = videoDevices[deviceIndex];

  videoElement.pause();

  if (videoElement.srcObject) {
    for (const track of videoElement.srcObject.getTracks()) {
      track.stop();
    }
  }

  const options = {
    video: device ? 
      { deviceId: { exact: device.deviceId } } :
      { deviceId: undefined },
    audio: false
  };
  console.log(options);

  try {
    const stream = await getUserMedia(options);
    videoElement.srcObject = stream;
    console.log(stream);
    videoElement.play();
  } catch (e) {
    throw new Error('video: Error on opening video.');
  }

};

const switchVideo = async () => {
  if (videoDevices.length > 0) {
    deviceIndex = (deviceIndex + 1) % videoDevices.length;
  }
  await open(deviceIndex);
};

const reopen = async () => {
  await open(deviceIndex);
};

export default {
  open,
  switchVideo,
  reopen,
  element: videoElement,
  get devices() {
    return videoDevices;
  },
  get settings() {
    return videoElement.srcObject?.getTracks()[0].getSettings();
  }
};
