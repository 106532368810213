import * as PIXI from 'pixi.js';
import app from '../canvas';
import Detector from './detector';
import { scaleValue, normalizeRadians } from '../util';

const g = new PIXI.Graphics();
const container = new PIXI.Container();
app.stage.addChild(container);

const coords = {};

const emitters = {
  positionUpdated: [],
  directionUpdated: []
};

const emit = (target, ...args) => {
  for (const callback of emitters[target]) {
    callback(...args);
  }
};

const getMouse = (() => {
  
  let touch;
  const onTouch = e => {
    touch = [
      e.touches[0]?.pageX,
      e.touches[0]?.pageY
    ];
  };
  document.addEventListener('touchstart', onTouch);
  document.addEventListener('touchmove', onTouch);

  return () => {
    return touch || [
      app.renderer.plugins.interaction.mouse.global.x,
      app.renderer.plugins.interaction.mouse.global.y
    ];
  };

})();

const getScreenSize = () => [
  app.renderer.width / app.renderer.resolution,
  app.renderer.height / app.renderer.resolution
];

const text = new PIXI.Text(
  'Hello',
  { fontFamily : 'Noto Sans CJK JP', fontSize: 12, fill : 0xffffff, align : 'left' }
);
text.x = 50;
text.y = 40;
container.addChild(text);

app.ticker.add(() => {
  
  if (!coords.buildings) {
    return;
  }

  coords.player = Detector.getViewCoords('player');
  coords.beam = Detector.getViewCoords('beam');

  g.clear();

  g.lineStyle(1, 0xffffff);
  for (const coords of coords.buildings) {
    const path = coords.flat();
    g.beginFill(0xffffff, 0.2);
    g.drawPolygon(path);
    g.endFill();
  }
  g.lineStyle();

  g.beginFill(0xff0000);
  g.drawCircle(...coords.player, 10);
  g.endFill();

  if (DEBUG_MODE) {
    const [mouseX, mouseY] = getMouse();
    // console.log('mouse', mouseX, mouseY);
    const [width] = getScreenSize();
    const angle = normalizeRadians(
      scaleValue(mouseX, 0, width, -Math.PI, Math.PI)
    );
    Detector.setPlayerDirection(angle, 90);
    emit('directionUpdated', angle);
  }

  // console.log(coords.beam);
  g.lineStyle(3, 0xffffff);
  g.moveTo(...coords.beam[0]);
  g.lineTo(...coords.beam[1]);
  g.lineStyle();

  text.text = Detector.currentHit ?
    JSON.stringify(Detector.currentHit, null, 2) :
    Detector.outOfArea ? 'Out of area' : '-';

});

document.addEventListener('keydown', () => {
  if (!DEBUG_MODE) {
    return;
  }
  const coord = Detector.viewToMapCoord(getMouse());
  Detector.setPlayerCoord(coord);
  emit('positionUpdated', { latitude: coord[0], longitude: coord[1] });
  console.log(coord); 
});

const onResize = () => {
  Detector.setViewSize(getScreenSize());
  coords.buildings = Detector.getViewCoords('buildings');
};

app.renderer.addListener('resize', onResize);
onResize();

container.addChild(g);

let DEBUG_MODE;
export default {
  setDebugMode(status) {
    DEBUG_MODE = status
  },
  toggleDisplay() {
    container.visible = !container.visible;
  },
  on: (target, callback) => {
    emitters[target].push(callback);
  }
};
