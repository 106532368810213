import { degToRad, osName } from '../util';

const emitters = {
  positionUpdated: [],
  directionUpdated: []
};

const emit = (target, ...args) => {
  for (const callback of emitters[target]) {
    callback(...args);
  }
};

let coords, tLastTime;

const getCompassHeading = (alpha, beta, gamma) => {
  const degtorad = Math.PI / 180;

  const _x = beta ? beta * degtorad : 0;
  const _y = gamma ? gamma * degtorad : 0;
  const _z = alpha ? alpha * degtorad : 0;

  const cY = Math.cos(_y);
  const cZ = Math.cos(_z);
  const sX = Math.sin(_x);
  const sY = Math.sin(_y);
  const sZ = Math.sin(_z);

  const Vx = -cZ * sY - sZ * sX * cY;
  const Vy = -sZ * sY + cZ * sX * cY;

  let compassHeading = Math.atan(Vx / Vy);

  if (Vy < 0) {
    compassHeading += Math.PI;
  }
  else if (Vx < 0) {
    compassHeading += 2 * Math.PI;
  }

  return compassHeading;
};

const positionReceived = data => {
  const t = Date.now();
  const dt = t - tLastTime;
  tLastTime = t;
  // coords = fromLonLat([data.coords.longitude, data.coords.latitude]);
  // console.log(data.coords);
  coords = data.coords;
  // console.log(data);
  emit('positionUpdated', data.coords, dt, data.coords.accuracy);
};

const startGNSS = (timeout = 10000, enableHighAccuracy = true) => {
  navigator.geolocation.watchPosition(
    data => {
      positionReceived(data);
    },
    e => {
      console.error(e);
    },
    { maximumAge: 0, timeout, enableHighAccuracy }
  );
};

let lastDirection = 0;
const directionResolution = Math.PI * 2 / 144;
const requestPermission = () => {
  const addEventListener = () => {
    const eventName = osName === 'ios' ?
      'deviceorientation' :
      'deviceorientationabsolute';
    window.addEventListener(
      eventName,
      event => {
        // console.log(event);
        const direction =
          (osName === 'ios') ?
            degToRad(event.webkitCompassHeading) :
            getCompassHeading(
              event.alpha,
              event.beta,
              event.gamma
            );
        if (Math.abs(lastDirection - direction) > directionResolution) {
          emit('directionUpdated', direction, event.beta);
          lastDirection = direction;
        }
      },
      true
    );
  }

  if (
    typeof DeviceOrientationEvent !== 'undefined' &&
    typeof DeviceOrientationEvent.requestPermission === 'function'
  ) {
    DeviceOrientationEvent.requestPermission()
    .then(permissionState => {
      if (permissionState === 'granted') {
        addEventListener();
      } else {
        console.error('no permission for device orientation.');
      }
    })
    .catch(console.error)
  } else {
    addEventListener();
  }
};

let initialized = false;
const init = () => {
  if (initialized) {
    return;
  }
  startGNSS();
  requestPermission();
  initialized = true;
};

// #####################################
if (typeof window.SB === 'undefined') {
  window.SB = {};
}
const GNSS = {
  getCompassHeading,
  getCoords: () => coords,
  getDirection: () => lastDirection,
  on: (target, callback) => {
    emitters[target].push(callback);
  },
  init
};

export default GNSS;
window.SB.GNSS = GNSS;

